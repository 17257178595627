<template>
  <div class="_bg-default _80w _100vh d-flex">
    <div class="_100top _full-w pa-3">
      <v-card class="radius-card px-3" :loading="loader">
        <v-card-title>
          Agenda List
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            solo
            dense
            rounded
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @keyup="fetchAgenda"
          ></v-text-field>
        </v-card-title>
        <div v-if="agenda">
          <v-data-table
            :headers="headers"
            :items="agenda.data"
            hide-default-footer
            :page.sync="page"
            :items-per-page="perPage"
            class="elevation-0 my-5"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex">
                <v-btn
                  icon
                  depressed
                  outlined
                  color="cyan"
                  class="mr-2"
                  content="View Agenda"
                  v-tippy="{
                    arrow: true,
                    arrowType: 'round',
                    animation: 'fade',
                    theme: 'light',
                    maxWidth: 100,
                  }"
                  @click.prevent="view(item)"
                >
                  <v-icon dark small> mdi-eye </v-icon>
                </v-btn>

                <v-btn
                  icon
                  depressed
                  outlined
                  color="green"
                  class="mr-2"
                  content="Edit Agenda"
                  v-tippy="{
                    arrow: true,
                    arrowType: 'round',
                    animation: 'fade',
                    theme: 'light',
                    maxWidth: 100,
                  }"
                  @click.prevent="edit(item)"
                >
                  <v-icon dark small> mdi-pencil </v-icon>
                </v-btn>

                <v-btn
                  icon
                  depressed
                  outlined
                  color="red"
                  class="mr-2"
                  content="Delete Agenda"
                  v-if="role == 1"
                  v-tippy="{
                    arrow: true,
                    arrowType: 'round',
                    animation: 'fade',
                    theme: 'light',
                    maxWidth: 100,
                  }"
                  @click.prevent="deleteItem(item.id)"
                >
                  <v-icon dark small> mdi-cancel </v-icon>
                </v-btn>

                <v-btn
                  icon
                  depressed
                  outlined
                  color="purple"
                  content="Participant Agenda"
                  v-if="role == 1"
                  v-tippy="{
                    arrow: true,
                    arrowType: 'round',
                    animation: 'fade',
                    theme: 'light',
                    maxWidth: 100,
                  }"
                  @click.prevent="listParticipant(item)"
                >
                  <v-icon dark small> mdi-account-group </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
              v-model="page"
              :total-visible="7"
              @input="fetchAgenda"
              :length="agenda.meta.last_page"
            ></v-pagination>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "listAgenda",
  computed: {
    ...mapState({
      agenda: (state) => state.agenda.agenda,
      role: (state) => state.role,
    }),
  },
  data() {
    return {
      loader: false,
      search: "",
      headers: [
        { text: "ID", value: "id" },
        { text: "TITLE", value: "judul" },
        { text: "SPEAKER", value: "pemateri" },
        { text: "LOCATION", value: "lokasi" },
        { text: "ACTIONS", value: "actions" },
      ],
      page: 1,
      perPage: 10,
      selectedEvt: null,
    };
  },
  mounted() {
    this.fetchAgenda();
  },
  methods: {
    fetchAgenda() {
      let data = {
        page: this.page,
        limit: this.perPage,
        search: this.search,
      };
      this.loader = true;
      this.$store.dispatch("agenda/listAgenda", data).then(() => {
        this.loader = false;
      });
    },
    view(item) {
      this.$router.push(`/admin/agenda-view/${item.id}`);
    },
    edit(item) {
      this.$router.push(`/admin/agenda-edit/${item.id}`);
    },
    deleteItem(item) {
      Swal.fire({
        title: "Apakah anda yakin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("agenda/deleteAgenda", item).then(() => {
            this.fetchAgenda();
            Swal.fire({
              icon: "success",
              title: "Agenda terhapus!",
              showConfirmButton: false,
              position: "top-end",
              toast: true,
              timer: 3000,
            });
          });
        }
      });
    },
    listParticipant(item) {
      console.log(item);
      this.$router.push(
        `/admin/agenda-participant/${item.id}/${item.tipe_agenda}`,
      );
    },
  },
};
</script>

<style></style>
